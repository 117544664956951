<template>
  <div>
    <filter-component :showFilters="true"></filter-component>

    <!-- <b-row>
      <b-col md="12" lg="12">
       
        <b-card header="SOLD RIGHTS (Year 2022-23)" header-tag="h5">
          <b-card-text>
            <apexchart
              type="line"
              height="350"
              :options="chartOptionsRightsByMonth"
              :series="seriesRightsByMonth"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row> -->
    <!-- <b-row>
      <b-col md="12" lg="12">
      
        <b-card header="REVENUE (CHF) (Year 2022-23)" header-tag="h5">
          <b-card-text>
            <apexchart
              type="line"
              height="350"
              :options="chartOptionsRevenue"
              :series="seriesRevenue"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row> -->
    <b-row>
      <b-col md="6" lg="6" v-if="showGraphs == true">
        <!-- footer="2 days ago"
          class="text-center"
          footer-class="text-muted" -->
        <b-card
          header="UPCOMING THEATRICAL/CINEMATIC RELEASES (upcoming 6 months)"
          header-tag="h5"
        >
          <b-card-text>
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsRelease"
              :series="seriesRelease"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6" lg="6" v-if="showGraphs == true">
        <b-card
          header="MILESTONES TO BE PAID (upcoming 6 months)"
          header-tag="h5"
        >
          <b-card-text>
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsMilestone"
              :series="seriesMileStone"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6" lg="6" v-if="showGraphs == true">
        <b-card header="CONTRACTS (last 6 months)" header-tag="h5">
          <b-card-text>
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsContracts"
              :series="seriesContracts"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6" lg="6" v-if="showGraphs == true">
        <b-card
          header="CONTRACTS ABOUT TO EXPIRE (upcoming 6 months)"
          header-tag="h5"
        >
          <b-card-text>
            <apexchart
              type="bar"
              height="350"
              :options="chartOptionsContractsExp"
              :series="seriesContractsExp"
            ></apexchart>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FilterComponent from "../../components/Filters.vue";
import apexchart from "vue-apexcharts";
import * as DashBoardServices from "@/apiServices/DashboardServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    apexchart,
    FilterComponent,
  },
  data() {
    return {
      seriesRevenue: [
        // {
        //   name: "TVOD",
        //   data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 69, 91, 148],
        // },
        {
          name: "All",
          data: [50, 15, 69, 78, 25, 96, 74, 15, 14, 74, 15, 14],
        },
      ],
      chartOptionsRevenue: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      seriesRightsByMonth: [
        {
          name: "TVOD",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 69, 91, 148],
        },
        {
          name: "THEATRICAL",
          data: [50, 15, 69, 78, 25, 96, 74, 15, 14, 74, 15, 14],
        },
      ],
      chartOptionsRightsByMonth: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        // title: {
        //   text: "Product Trends by Month",
        //   align: "left",
        // },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      seriesContracts: [],
      chartOptionsContracts: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          // type: "datetime",
          categories: [],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        fill: {
          opacity: 1,
        },
      },
      seriesRelease: [],
      chartOptionsRelease: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          // type: "datetime",
          categories: [],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        fill: {
          opacity: 1,
        },
      },
      seriesMileStone: [],
      chartOptionsMilestone: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          // type: "datetime",
          categories: [],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        fill: {
          opacity: 1,
        },
      },
      seriesContractsExp: [],
      chartOptionsContractsExp: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          // type: "datetime",
          categories: [],
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        fill: {
          opacity: 1,
        },
      },
      showGraphs: false,
    };
  },

  directives: {
    Ripple,
  },

  beforeMount() {
    this.getDashBoardData();
  },

  methods: {
    async getDashBoardData() {
      try {
        let payload = {};
        const response = await DashBoardServices.GetDashBoardStats(payload);
        if (response.data.status) {
          this.chartOptionsContracts.xaxis.categories =
            response.data.data.lastSixMonthArray;
          this.seriesContracts = response.data.data.seriesContracts;

          this.seriesContractsExp = response.data.data.seriesContractsExp;
          this.chartOptionsContractsExp.xaxis.categories =
            response.data.data.upcommingSixMonthArray;

          this.seriesRelease = response.data.data.seriesRelease;

          this.chartOptionsRelease.xaxis.categories =
            response.data.data.upcommingSixMonthArray;
          this.seriesMileStone = response.data.data.seriesMileStone;
          this.chartOptionsMilestone.xaxis.categories =
            response.data.data.upcommingSixMonthArray;

          this.showGraphs = true;
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
};
</script>
